import { Link } from "@remix-run/react";
import { appDetails } from "~/utils/misc";
import logo from "/img/logo.svg";

const footerNavigation = {
  solutions: [
    { name: appDetails.title, href: "/" },
    {
      name: "Disruptive Futures Platform",
      href: "https://aptim-solutions.com/solutions/disruptive-futures-platform/",
      external: true,
    },
    {
      name: "Transformation Projects",
      href: "https://aptim-solutions.com/solutions/transformation-projects/",
      external: true,
    },
    {
      name: "Transformation Audits",
      href: "https://aptim-solutions.com/solutions/transformation-audits/",
      external: true,
    },
    {
      name: "Development Centres",
      href: "https://aptim-solutions.com/solutions/development-centres",
      external: true,
    },
  ],
  resources: [
    {
      name: "Resources",
      href: "https://aptim-solutions.com/resources/",
      external: true,
    },
  ],
  company: [
    {
      name: "About",
      href: "https://aptim-solutions.com/about-us/",
      external: true,
    },
  ],
  legal: [
    {
      name: "Cookies",
      href: "https://aptim-solutions.com/cookies-policy/",
      external: true,
    },
    {
      name: "Privacy",
      href: "https://aptim-solutions.com/privacy-policy/",
      external: true,
    },
    {
      name: "Terms",
      href: "https://aptim-solutions.com/terms-of-use/",
      external: true,
    },
  ],
};

export function Footer() {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <footer
        aria-labelledby="footer-heading"
        className="relative border-t border-primary py-4"
      >
        <h2 id="footer-heading" className="sr-only"></h2>
        <div className="flex flex-wrap items-start">
          <div className="order-1 md:w-[30%] pr-4">
            <img alt={appDetails.companyName} src={logo} className="h-7" />
            <div className="text-primary text-sm font-light">
              {appDetails.companyFooter}
            </div>
          </div>
          <div className="sm:w-full md:order-1 md:w-[70%] grid grid-cols-4 gap-8 xl:col-span-2 xl:mt-0">
            {Object.keys(footerNavigation).map((menu) => {
              return (
                <div key={menu} className="md:grid md:grid-cols-2 gap-2">
                  <div className="w-[100%] col-span-2">
                    <h3 className="text-sm font-semibold leading-6 text-primary">
                      {menu.toUpperCase()}
                    </h3>
                    <ul role="list" className="mt-2 space-y-4 w-full">
                      {footerNavigation[menu].map((item) => {
                        if (item.external) {
                          return (
                            <li key={item.name}>
                              <a
                                href={item.href}
                                className="text-sm text-muted hover:text-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.name}
                              </a>
                            </li>
                          );
                        } else {
                          return (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className="text-sm text-muted hover:text-primary"
                              >
                                {item.name}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </footer>
    </div>
  );
}
